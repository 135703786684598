@import "../../../_constants.sass";

#reservations {
    .subscriber {
        margin-bottom: 36px;

        .form-input {
            margin-bottom: 16px; }

        h4 {
            margin-bottom: 8px; }

        input {
            padding: 8px 16px;
            border: 1px solid $colorPurple;
            border-radius: 4px;
            box-sizing: border-box;
            font-family: $fontFamilyOpenSans;
            font-size: $fontSizeM; }

        textarea {
            width: 100%;
            padding: 8px;
            min-height: 120px;
            box-sizing: border-box;
            font-family: $fontFamilyOpenSans;
            font-size: $fontSizeM; }

        .form-input.hykm input {
            width: 480px; } } }
