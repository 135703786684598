@import "../_constants.sass";

#reserve-success {
    width: 80%;
    max-width: 480px;
    margin: 64px auto 240px;

    h1 {
        color: $colorDarkPurple; }

    a {
        color: $colorLightPurple;
        text-decoration: underline; }

    section {
        margin: 32px 0;

        strong {
            color: $colorLightPurple; }

        .address {
            margin: 16px 0;
            font-weight: 700; }

        iframe {
            margin: 32px 0; }

        ul {
            li {
                margin: 8px 0;
                font-size: 14px; } } } }


