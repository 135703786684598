@import "../_constants.sass";

#reservations {

    h3, h4, p {
        color: $colorTextGray; }

    .reservations-container {
        width: 480px;
        margin: 64px auto 240px;

        @media screen and (max-width: $screenWidthMedium) {
            max-width: 80%;
            margin: 32px auto 120px; }

        .reserve-view-selector {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 64px 0;

            @media screen and (max-width: $screenWidthMedium) {
                margin: 32px 0; }

            button {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media screen and (max-width: $screenWidthMedium) {
                    flex-direction: column; } }

            button.active {
                background-color: $colorDarkPurple; }

            button img {
                width: 24px;
                margin: 0 8px 0 0;

                @media screen and (max-width: $screenWidthMedium) {
                    margin: 0 0 4px 0; } } }

        .subscribe-container {
            h2 {
                color: $colorPurple; }

            a {
                color: $colorLightPurple; }

            .text-container {
                margin-bottom: 36px; } } } }

