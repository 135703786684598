@import "../_constants.sass";

#reserve-form .reserve-form-intro {
    margin: 32px 0;
    padding: 32px;
    border: 1px solid $colorPurple;
    border-radius: 8px;

    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center; }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        margin-right: 12px;
        border-radius: 100%;
        background-color: $colorPurple;

        img {
            width: 32px; } }

    .alert h4 {
        margin-top: 16px;
        color: red; } }

#reserve-form {
    .select-form-type {
        margin-bottom: 16px;

        h3 {
            padding: 8px 0; }

        input {
            margin: 8px 8px 8px 0; } }

    .fill-form {
        padding-bottom: 16px;

        section {
            margin-bottom: 32px; }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 16px 0;

            button.clear {
                background-color: $colorGrayDark;
                color: $colorWhite; } } }

    a {
        color: $colorLightPurple; }

    .form-input {
        margin-bottom: 16px;

        h4 {
            margin-bottom: 8px; }

        input {
            padding: 8px 16px;
            border: 1px solid $colorPurple;
            border-radius: 4px;
            box-sizing: border-box;
            font-family: $fontFamilyOpenSans;
            font-size: $fontSizeM; }

        textarea {
            width: 100%;
            padding: 8px;
            min-height: 120px;
            box-sizing: border-box;
            font-family: $fontFamilyOpenSans;
            font-size: $fontSizeM; }

        &.checkbox {
            margin: 8px 0;

            input {
                display: inline-block;
                position: relative;
                top: 2px;
                margin-right: 8px;
                vertical-align: top; }

            .label {
                display: inline-block;
                max-width: calc(100% - 28px);

                h4 {
                    font-weight: 400;
                    color: $colorTextBlack; }

                h5 {
                    font-weight: 400;
                    color: $colorTextGray; } } }

        &.name input {
            width: 360px;
            @media screen and (max-width: $screenWidthMedium) {
                width: 240px; } }

        &.email input {
            width: 240px; }
        &.phone input {
            width: 160px; }
        &.num-guests input {
            width: 64px; }
        &.location input {
            width: 360px; }
        &.parking input {
            width: 100%; }
        &.diet input {
            width: 100%; }

        .error {
            color: red; } }

    .select-date-time {
        h4 {
            margin-bottom: 8px; }

        .date-picker {
            margin: 16px 0;

            .SingleDatePickerInput {
                width: 200px;
 } }                // hack to fix formating issue?

        .time-picker {
            margin: 16px 0;
            max-width: 160px; }

        p b {
            color: $colorLightPurple; } }

    .estimates {
        margin: 24px 0;
        color: $colorTextGray;

        h4 {
            margin: 0; }
        p {
            margin: 8px 0; }
        strong {
            color: $colorLightPurple; } } }
