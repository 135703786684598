@import "../_constants.sass";

#profile {
    text-align: center;

    .outer-circle {
        position: relative;
        width: 160px;
        height: 160px;
        margin: 0 auto;
        background-color: $colorPurple;
        border-radius: 100%; }

    .mask-circle {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 152px;
        height: 152px;
        border-radius: 100%;
        background-image: url("./../assets/profile_headshot.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden; }

    h3 {
        margin: 16px 0; }

    h2 {
        margin: 24px 0; }

    p {
        width: 480px;
        margin: 0 auto;

        @media screen and (max-width: $screenWidthSmall) {
            width: 80%; } } }
