@import "../../../_constants.sass";

#sample-menu {
    margin: 16px 0;

    a {
        color: $colorPurple;
        font-size: $fontSizeM;
        text-decoration: underline;
        cursor: pointer; } }
