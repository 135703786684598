@import "../../../_constants.sass";

#reserve-form-additions {

    h4.additions {
        margin: 8px 0; }

    .form-input.checkbox {
        margin: 0 !important; }

    .prompt {
        margin-left: 24px;
        margin-bottom: 16px;
        font-size: 14px;

        a {
            cursor: pointer; }

        a:hover {
            text-decoration: underline; } }

    .modal-content {
        text-align: center;

        ul {
            padding: 0;

            li {
                margin: 16px 0;
                list-style: none; }

            h5 {
                font-size: 16px; }
            p {
                font-size: 14px; } } } }
