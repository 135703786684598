@import "../../../_constants.sass";

#tos {

    input {
        position: relative;
        top: 1px;
        margin-right: 6px; }

    .label {
        display: inline-block;
        width: calc(100% - 36px);
        vertical-align: top;

        p {
            margin: 0; }

        a {
            text-decoration: underline;
            cursor: pointer; } }

    .modal-content {
        padding: 48px;

        h2,
        h3 {
            color: $colorTextGray; }

        h2 {
            margin-bottom: 24px; }

        p {
            max-width: 100%;
            margin: 24px 0; }

        u {
            color: $colorLightPurple; } } }
