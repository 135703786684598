@import "../_constants.sass";

#header {
    width: 100%;
    color: $colorWhite;

    .links a {
        margin: 0 24px;
        color: $colorWhite;
        font-family: $fontFamilyOptima;
        font-size: 16px;
        letter-spacing: 1px;
        text-decoration: none;

        &:hover {
            color: $colorGold; }

        &.active {
            color: $colorGold; }

        @media screen and (max-width: $screenWidthSmall) {
            margin: 0 8px; } } }

#header .header-full {
    position: static;
    padding: 32px 0;
    background-color: $colorDarkPurple;
    text-align: center;

    img.logo {
        width: 64px;

        @media screen and (max-width: $screenWidthSmall) {
            width: 48px; } }

    .links {
        margin-top: 24px;

        @media screen and (max-width: $screenWidthSmall) {
            margin-top: 16px; } } }

#header .header-sticky {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    z-index: 10;

    img.logo {
        width: 64px;

        @media screen and (max-width: $screenWidthSmall) {
            width: 32px; } }

    .links a {
        @media screen and (max-width: $screenWidthSmall) {
            margin: 0 8px;
            font-size: $fontSizeM; } } }
