// Colors
$colorBlack: #1C1B1A;
$colorGrayDark: #3D3D41;
$colorGrayLight: #BEBDC4;
$colorWhite: #ffffff;
$colorOffWhite: #EBE6E1;

$colorDarkPurple: #221F2B;
$colorPurple: #393548;
$colorLightPurple: #544B75;
$colorGold: #D1BE13;

$colorTextBlack: $colorBlack;
$colorTextGray: $colorGrayDark;
$colorBase: $colorDarkPurple;

// Fonts - Font Families
@font-face {
  font-family: 'Optima';
  src: url('assets/fonts/Optima/Optima.eot');
  src: url('assets/fonts/Optima/Optima.eot?#iefix') format('embedded-opentype');
  src: url('assets/fonts/Optima/Optima.woff') format('woff');
  src: url('assets/fonts/Optima/Optima.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }

$fontFamilyOptima: 'Optima', 'sans-serif';
$fontFamilyOpenSans: 'Open Sans', sans-serif;
$fontFamilySourceSans: 'Source Sans Pro', sans-serif;

$fontFamilyHeading: $fontFamilyOptima;
$fontFamilyTitle: $fontFamilySourceSans;
$fontFamilyText: $fontFamilyOpenSans;

// Fonts - For reading texts
$fontSizeM: 14px;
$fontSizeL: 16px;
$fontSizeXL: 18px;
$fontSizeXXL: 20px;

// Line-Heights
$lineHeightM: 22px;
$lineHeightL: 24px;
$lineHeightXL: 28px;
$lineHeightXXL: 32px;

// Spacing
$spacing1x: 8px;
$spacing1_5x: ($spacing1x * 1.5);
$spacing2x: ($spacing1x * 2);
$spacing3x: ($spacing1x * 3);
$spacing4x: ($spacing1x * 4);
$spacing5x: ($spacing1x * 5);
$spacing6x: ($spacing1x * 6);
$spacing8x: ($spacing1x * 8);
$spacing10x: ($spacing1x * 10);
$spacing11x: ($spacing1x * 11);
$spacing12x: ($spacing1x * 12);
$spacing16x: ($spacing1x * 16);
$spacing20x: ($spacing1x * 20);
$spacing24x: ($spacing1x * 24);
$spacing28x: ($spacing1x * 28);
$spacing32x: ($spacing1x * 32);
$spacing36x: ($spacing1x * 36);
$spacing40x: ($spacing1x * 40);
$spacing42x: ($spacing1x * 42);
$spacing48x: ($spacing1x * 48);
$spacing56x: ($spacing1x * 56);
$spacing64x: ($spacing1x * 64);
$spacing72x: ($spacing1x * 72);
$spacing80x: ($spacing1x * 80);
$spacing96x: ($spacing1x * 96);
$spacing100x: ($spacing1x * 100);
$spacing120x: ($spacing1x * 120);
$spacing128x: ($spacing1x * 128);
$spacing160x: ($spacing1x * 160);

// Responsiveness
$screenWidthSmall: 640px;
$screenWidthMedium: 800px;
$screenWidthLarge: 1080px;

// Misc.
$defaultBorderRadius: 2px;
