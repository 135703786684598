@import "../_constants.sass";

#footer {
    width: 100%;
    padding: 80px 0;
    background-color: $colorPurple;
    color: $colorWhite;
    text-align: center;

    #footer-contact {
        margin-top: 32px; }

    img.logo {
        width: 80px; }

    .icons {
        margin-top: 16px;

        img {
            margin: 0 8px;

            &.ig {
                width: 24px; }
            &.li {
                width: 22px; } } }

    h4 {
        margin: 4px 0; } }
