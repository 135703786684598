@import "../_constants.sass";

.banner {
    position: relative;
    width: 100%;
    height: 480px;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $colorBlack; }

    .banner-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }

    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center; }

    &.not-header {
        h1 {
            margin-bottom: 24px; }

        h2 {
            margin-bottom: 24px; }

        p {
            margin-bottom: 24px; } } }


