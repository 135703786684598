@import "../../../_constants.sass";

#reservations {

    .contact-me {
        margin: 32px 0;
        padding: 32px 64px;
        border: 1px solid $colorPurple;
        border-radius: 8px;

        img.profile {
            display: block;
            width: 80%;
            max-width: 360px;
            margin: 32px auto;
            border-radius: 8px;

            @media screen and (max-width: $screenWidthMedium) {
                margin: 24px auto; } }

        h3 {
            margin-bottom: 16px;
            text-align: center; }

        .contact-line {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 36px;
            margin: 12px 0;

            @media screen and (max-width: $screenWidthMedium) {
                font-size: $fontSizeM; }

            img {
                margin-right: 24px;
                vertical-align: middle;

                @media screen and (max-width: $screenWidthMedium) {
                    margin-right: 8px; } }

            a {
                color: $colorTextBlack; } } } }
