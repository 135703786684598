@import "../_constants.sass";

#image-community {

    .community-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 640px;
        margin: 0 auto;

        @media screen and (max-width: $screenWidthSmall) {
            width: 100%; } }

    .community-card {
        width: 200px;
        margin-bottom: 16px;
        cursor: pointer;
        transition: 0.3s linear transform;

        @media screen and (max-width: $screenWidthSmall) {
            width: 360px;
            margin: 16px auto; }

        &:hover {
            transform: scale(1.05); }

        img {
            width: 100%; } } }


