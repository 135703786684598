@import "../_constants.sass";
.simple-modal {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(33, 33, 33, 0.9);
        z-index: 10; }

    .modal-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 560px;
        height: 75%;
        padding-bottom: 64px;
        box-sizing: border-box;
        overflow: auto;
        background-color: $colorOffWhite;
        z-index: 11;

        @media screen and (max-width: $screenWidthMedium) {
            height: 100%; }

        .btn-close {
            position: absolute;
            top: 12px;
            right: 12px;
            padding: 0;
            background: none;
            z-index: 12;

            img {
                width: 24px;
                height: 24px; } }


        .banner .overlay {
            height: 180px;
            opacity: 0.8 !important;
            z-index: 0; }

        section {
            margin: 16px 0;
            text-align: center; }

        .menu-item {
            margin-bottom: 16px; }

        h4 {
            margin: 24px 0;
            color: $colorLightPurple; }

        h5 {
            margin: 0;
            font-size: $fontSizeM;
            color: $colorTextBlack; }

        p {
            max-width: 50%;
            margin: 0px auto;
            color: $colorTextGray;

            &.description {
                max-width: 80%; } } }

    /* width */
    ::-webkit-scrollbar {
        width: 8px; }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $colorGrayLight; }


    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $colorPurple; }


    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $colorLightPurple; } }
