@import "../_constants.sass";

#gallery {
    background-color: $colorDarkPurple;

    .section {
        margin: 72px 0;

        @media screen and (max-width: $screenWidthMedium) {
            margin: 36px; }

        &.text {
            width: 100%;
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            color: $colorWhite; } }

    #home-banner {
        position: relative;

        h2 {
            max-width: 360px;
            line-height: 32px;
            font-family: $fontFamilyOptima;
            font-weight: 400;
            letter-spacing: 1px;

            @media screen and (max-width: $screenWidthMedium) {
                max-width: 480px;
                font-size: 16px; } }

        button {
            margin-top: 32px;
            font-size: 20px;

            @media screen and (max-width: $screenWidthMedium) {
                margin-top: 24px;
                font-size: 16px; } }

        .banner .banner-img {
            background-size: contain;
            background-position: center center;

            @media screen and (max-width: $screenWidthLarge) {
                background-size: cover;
                background-position: 70% center; } }

        .caret {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 24px;
            padding: 0;
            background: none;
            transition: 0.3s linear transform;

            &:hover {
                transform: translate(-50%, 15%); }

            img {
                width: 48px;

                @media screen and (max-width: $screenWidthMedium) {
                    width: 36px; } } } } }
