@import "../_constants.sass";

#story {

    .story-section {
        display: flex;
        flex-direction: row;
        min-height: 280px;
        justify-content: center;
        align-items: stretch;

        @media screen and (max-width: $screenWidthMedium) {
            flex-wrap: wrap-reverse; }

        &.white {
            background-color: $colorOffWhite;
            h3 {
                color: $colorTextBlack; }
            p {
                color: $colorTextBlack; } }

        &.dark {
            background-color: $colorDarkPurple;
            color: $colorWhite; }
        &.light {
            background-color: $colorPurple;
            color: $colorWhite; }

        &.right,
        &.left {
            .content {
                width: 50%;
                min-height: 360px;
                padding: 64px;
                box-sizing: border-box;
                @media screen and (max-width: $screenWidthMedium) {
                    width: 100%; } }

            .img-container {
                position: relative;
                width: 50%;
                overflow: hidden;
                @media screen and (max-width: $screenWidthMedium) {
                    width: 100%;
                    height: 320px; }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%; } } }

        &.left {
            text-align: left;
            @media screen and (max-width: $screenWidthMedium) {
                text-align: center; } }
        &.right {
            flex-direction: row-reverse;
            text-align: right;
            @media screen and (max-width: $screenWidthMedium) {
                text-align: center; } }

        &.center {
            max-width: 480px;
            margin: 32px auto;
            text-align: center;
            @media screen and (max-width: $screenWidthMedium) {
                max-width: 80%; }

            .content {
                padding: 32px 0;

                h3 {
                    display: inline-block;
                    padding: 24px 0 8px;

                    &.action {
                        border-top: 1px solid $colorBlack; } } } }

        &.action button {
            display: block;
            margin: 16px auto; } } }
